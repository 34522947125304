import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Button from "../Button/Button";
import {ReactComponent as SoftationFooter} from "../../Utils/SVGs/SoftationFooter.svg"
import {ReactComponent as Linkedin} from "../../Utils/SVGs/linkedin.svg";
import {ReactComponent as Instagram} from "../../Utils/SVGs/Instagram.svg"
import {ReactComponent as Facebook} from "../../Utils/SVGs/Facebook.svg"

const Footer = () => {
    return(
            <Box minWidth={"100%"} display={"flex"} width={"100%"} justifyContent={"space-between"} flexDirection={"column"}>
                <Box display={"flex"} flexWrap={'wrap'} gap={"20px"} justifyContent={"space-between"}>
                    <Box display={"flex"} gap={"45px"} flexDirection={"column"}>
                        <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                            <SoftationFooter/>
                            <Text fontSize={"46px"} color={"#0A0B0A"} fontWeight={700}>Softations</Text>
                        </Box>
                        <Text fontSize={"38px"} color={"#0A0B0A"} fontWeight={700}>Ready to elevate your<br/>Startup?</Text>
                    </Box>

                    <Box paddingTop={"20px"} display={"flex"} color={"#0A0B0A"} fontSize={"14px"} gap={"16px"} flexDirection={"column"}>
                <Text style={{fontWeight:700,cursor:"pointer"}}>Services</Text>
                <Text style={{cursor:"pointer"}}>Custom software development</Text>
                <Text style={{cursor:"pointer"}}>Mobile app development</Text>
                <Text style={{cursor:"pointer"}}>Software development consulting</Text>
                <Text style={{cursor:"pointer"}}>Web application development</Text>
                <Text style={{cursor:"pointer"}}>UI/UX design</Text>
            </Box>
            <Box paddingTop={"20px"} display={"flex"} color={"#0A0B0A"} fontSize={"14px"} gap={"16px"} flexDirection={"column"}>
                <Text style={{fontWeight:700,cursor:"pointer"}}>Industries</Text>
                <Text style={{cursor:"pointer"}}>Fintech</Text>
                <Text style={{cursor:"pointer"}}>E-Commerce</Text>
                <Text style={{cursor:"pointer"}}>Travel</Text>
                <Text style={{cursor:"pointer"}}>SaaS-Based</Text>
                <Text style={{cursor:"pointer"}}>Networking</Text>
                <Text style={{cursor:"pointer"}}>Social Media </Text>
            </Box>
            <Box paddingTop={"20px"} display={"flex"} color={"#0A0B0A"} fontSize={"14px"} gap={"16px"} flexDirection={"column"}>
                <Text style={{fontWeight:700,cursor:"pointer"}}>Navigation</Text>
                <Text style={{cursor:"pointer"}}>Services</Text>
                <Text style={{cursor:"pointer"}}>Case Studies</Text>
                <Text style={{cursor:"pointer"}}>Contact</Text>
                <Text style={{cursor:"pointer"}}>Blog</Text>
            </Box>
            <Box paddingTop={"20px"} display={"flex"} color={"#0A0B0A"} fontSize={"14px"} gap={"16px"} flexDirection={"column"} position={"relative"}>
                <Text style={{fontWeight:700,cursor:"pointer"}}>Company</Text>
                <Text style={{cursor:"pointer"}}>About</Text>
                <Text style={{cursor:"pointer"}}>Pricing</Text>
                <Text style={{cursor:"pointer"}}>Subscription</Text>
            </Box>
                </Box>
                <Box marginTop={"50px"} display={"flex"} flexWrap={"wrap"} columnGap={"100px"} rowGap={"50px"} alignItems={"center"}>
                    <Box display={"flex"} alignItems={"center"} gap={"20px"}>
                        <a href="https://www.linkedin.com/company/softations" target="_blank" rel="noopener noreferrer">
                          <Linkedin style={{ cursor: "pointer" }} />
                        </a>
                        <a href="https://www.instagram.com/softationssolutions/" target="_blank" rel="noopener noreferrer">
                          <Instagram style={{ cursor: "pointer" }} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61562014853904" target="_blank" rel="noopener noreferrer">
                          <Facebook style={{ cursor: "pointer" }} />
                        </a>
                    </Box>
                    <Button 
                    text={"Get a Free Quote"}
                    TextMargin={"0px"}
                    borderRadius={"12px"}
                    padding={"14px 23px"}
                    fontSize={"14px"}
                    fontWeight={"700"}
                    fontColor={"#0A0B0A"}
                    />
                </Box>
                <Box marginTop={"50px"} position={"relative"} display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={"40px"} color={"#0A0B0A"} fontSize={"13px"}>
                    <Text style={{opacity:"0.4",cursor:"pointer"}}>Privacy Policy</Text>
                    <Text style={{opacity:"0.4",cursor:"pointer"}}>Terms</Text>
                    <Text style={{textDecoration:"underline",cursor:"pointer"}}>info@softations.com</Text>
                    <Text style={{color:"#0A0B0A",opacity:"0.4",cursor:"pointer",marginTop:"auto",position:"relative",whiteSpace:"nowrap",right:"0px"}}>© 2024 Softations. All Rights Reserved.</Text>
                </Box>
            </Box>
    )
}

export default Footer;