// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQText{
    font-size: 22px;
}
.FAQHeadingBox{
    display: flex;
    align-items: center;
    width: 900px;
    gap: 40px;
}
.accordionBtn{
    padding: 30px 0px !important;
    justify-content: center;
    color: #191921;
    border-radius: 35px;
    border: 1px solid #EEEEEE;
}

@media screen and (max-width: 1150px) {
    .FAQText{
        font-size: 20px;
    }
    .FAQHeadingBox{
        width: auto;
        gap: 20px;
    }
}

@media screen and (max-width: 950px) {
    .FAQText{
        font-size: 18px;
    }
}

@media screen and (max-width: 850px) {
    .FAQText{
        font-size: 18px;
    }
    .FAQHeadingBox{
       margin: 0px 20px;
    }
}

@media screen and (max-width: 600px) {
    .FAQText{
        font-size: 16px;
    }
    .accordionBtn{
        padding: 10px 0px !important;
    }
    .FAQHeadingBox{
        gap: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Accordion/AccordionMap.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,SAAS;AACb;AACA;IACI,4BAA4B;IAC5B,uBAAuB;IACvB,cAAc;IACd,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,WAAW;QACX,SAAS;IACb;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;OACG,gBAAgB;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,4BAA4B;IAChC;IACA;QACI,SAAS;IACb;AACJ","sourcesContent":[".FAQText{\n    font-size: 22px;\n}\n.FAQHeadingBox{\n    display: flex;\n    align-items: center;\n    width: 900px;\n    gap: 40px;\n}\n.accordionBtn{\n    padding: 30px 0px !important;\n    justify-content: center;\n    color: #191921;\n    border-radius: 35px;\n    border: 1px solid #EEEEEE;\n}\n\n@media screen and (max-width: 1150px) {\n    .FAQText{\n        font-size: 20px;\n    }\n    .FAQHeadingBox{\n        width: auto;\n        gap: 20px;\n    }\n}\n\n@media screen and (max-width: 950px) {\n    .FAQText{\n        font-size: 18px;\n    }\n}\n\n@media screen and (max-width: 850px) {\n    .FAQText{\n        font-size: 18px;\n    }\n    .FAQHeadingBox{\n       margin: 0px 20px;\n    }\n}\n\n@media screen and (max-width: 600px) {\n    .FAQText{\n        font-size: 16px;\n    }\n    .accordionBtn{\n        padding: 10px 0px !important;\n    }\n    .FAQHeadingBox{\n        gap: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
