import { Box, Text } from "@chakra-ui/react";
import React from "react";
import AppColors from "../Utils/Color.js";
import Header from "../Components/Header/Header";
import EnterpriseCard from "../Components/Card/EnterpriseCard.js";
import QualitiesCard from "../Components/Card/QualitiesCard.js";
import "./Home.css";
import MeetingCard from "../Components/Form/MeetingCard.js";
import Comment from "../Components/CommentBox/Comment.js";
import AccordionMap from "../Components/Accordion/AccordionMap.js";
import MainBackground from "../Components/Images/75.png";
import Footer from "../Components/Footer/Footer.js";

const Home = () => {
  return (
    <Box
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Header />
      <Box className="boxContainer">
        <Text className="containerText">
          <em>We are Softations! Your Trusted Software</em> Solutions and
          Development Partner for startups. At Softations, we provide you with
          software development services that are efficient, useful, and aimed at
          your progress. 
        </Text>
        <Box className="booking">Book a meeting now!</Box>
      </Box>
      <Box
        style={{
          padding: "20px 40px 40px 40px",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
          width={"100%"}
          flexDirection={"column"}
        >
          <Text className="serviceText">
            Services we offer as a Software Development Company!
          </Text>
          <Box className="enterpriseContainer">
            {/* ------------------------------------- First Row  -------------------------------------- */}
            <EnterpriseCard
              title={"UI/UX Design"}
              description={
                "Designing smooth and interesting UI and UX to achieve high usability and pleasure while interacting with designed products."
              }
              type={"UI/UX Design"}
            />
            <EnterpriseCard
              title={"Custom Software Development"}
              description={
                "At Softations, we aim to provide you with a unique software solution that would meet your Startup needs."
              }
              type={"Custom Software Development"}
            />
            <EnterpriseCard
              title={"Software Development Consulting"}
              description={
                "Technical consultation towards strategic workflow assessment and improvement of your software development environments and scheduling."
              }
              type={"Software Development Consulting"}
            />
            {/* ------------------------------------- Second Row  -------------------------------------- */}
            <Box className="secondRow">
              <EnterpriseCard
                title={"Mobile App Development"}
                description={
                  "In today’s mobile-first world, reaching your audience on Android and iOS platforms is essential. Our expert mobile app developers are professionals who create high-quality applications with rich functionalities, delivering fast, smooth, and seamless performance across both operating systems."
                }
                type={"Mobile App Development"}
              />
              <EnterpriseCard
                title={"Web Application Development"}
                description={
                  "Having a strong online presence is very important in today’s world, especially for any startup business. Our full-stack web development services are all-inclusive based on the design and functionality of a website to meet user requirements of both design and functionality."
                }
                type={"Web Application Development"}
              />
            </Box>
            {/* ------------------------------------- Third Row  -------------------------------------- */}
            <Box className="thirdRow">
              <EnterpriseCard title={""} description={""} type={"Last Row"} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width={"90vw"} margin={"0px auto"}>
        <Box className="meetingCardSection">
          <Box
            justifyContent={"center"}
            gap={"18px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Text className="meetingCardSectionHeading">
              Softations - An Accelerator for Software Startup Success Story
              with Quality, Affordability & Communication!
            </Text>
            <Text className="meetingCardSectionText">
              At Softations, we’re dedicated to being your ultimate partner in
              software development, perfectly aligned with your startup’s needs.
              Whether you need innovative software solutions, expert full-stack
              web development, or streamlined SDLC processes, we deliver with
              unmatched quality, affordability, and clear communication. Our
              seasoned software engineers are committed to providing tailored
              solutions to your specific challenges and propel your startup
              forward.
            </Text>
          </Box>
          <Box className="meetingCard">
            <MeetingCard />
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        flexDirection={"column"}
        paddingBottom={"50px"}
        backgroundColor={AppColors.Black}
      >
        <Text className="chooseText">Why Choose Softations?</Text>
        <Box className="qualitiesGrid">
          <QualitiesCard
            text={"Excellence in Software Solutions"}
            description={
              "This is the reason why, when we find ourselves in intense competition, it's always the quality that differentiates us. Our team of strive software engineers hence utilize advanced technologies and industry standards in creating sustainable and excellent software."
            }
            svg={"Award"}
          />
          <QualitiesCard
            text={"Affordability Without Compromise"}
            description={
              "In the case of startups, cash is always a constraint and this is why it is important to work smart and get as much value as possible. Our system development processes are separated into agile approaches, which can help you to save your money and get maximum for your business."
            }
            svg={"Customer"}
          />
          <QualitiesCard
            text={"Communication That Drives Success"}
            description={
              "Communication skills play a major role in any process of software development. During project initiation, implementation, and project completion, our team shall be up-front and respond in real time to the client’s visions."
            }
            svg={"Team"}
          />
        </Box>
      </Box>
      {/* -------------------------   COMMENT   ------------------------------- */}
      <Box className="commentParentContainer">
        <Comment />
      </Box>
      {/* --------------------------------------------------------------------- */}
      <Box
        width={"90vw"}
        height={"auto"}
        display={"grid"}
        gridTemplateRows={"0.7fr"}
        gridTemplateColumns={"repeat(3, 1fr)"}
        margin={"0px auto 40px auto"}
      >
        <Box gridColumn="1 / 4" gridRow="1 / 2">
          <EnterpriseCard title={""} description={""} type={"Special"} />
        </Box>
      </Box>
      <Box padding={"40px 0px"} backgroundColor={"white"}>
        <Box
          width={"90vw"}
          display={"flex"}
          flexDirection={"column"}
          margin={"0px auto"}
          textAlign={"center"}
        >
          <Text className="FAQHeading">
            Frequently asked questions (FAQs)
          </Text>
          <AccordionMap />
        </Box>
        <Box
          width={"90vw"}
          height={"auto"}
          display={"grid"}
          gridTemplateRows={"300px"}
          gridTemplateColumns={"repeat(3, 1fr)"}
          margin={"0px auto 40px auto"}
        >
          <Box gridColumn="1 / 4" gridRow="1 / 2">
            <EnterpriseCard title={""} description={""} type={"BelowFAQ"} />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          backgroundImage: `url(${MainBackground})`,
        }}
        className="footerContainer"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
