import { Box, Text } from "@chakra-ui/react";
import React from "react";
import {ReactComponent as MeetingCardSvg} from "../../Utils/SVGs/MeetingCard.svg"
import Button from "../Button/Button.jsx";

const MeetingCard = () => {
    return(
        <Box backgroundColor={"#101010"} minWidth={window.innerWidth > 500 ? "325px":"100%"} padding={"20px 20px 40px 20px"} display={"flex"}  flexDirection={"column"} gap={"30px"} justifyContent={"space-between"} borderRadius={"20px"}>
           <Box display={"flex"} flexDirection={"column"} gap={"30px"} alignItems={"center"}>
           <MeetingCardSvg/>
            <Text fontSize={"20px"} fontWeight={600} color={"#E6E6E6"}>Let’s Work Together
                <Text fontSize={"14px"} fontWeight={500} color={"#999999"}>Let's Make Magic Happen Together!</Text>
            </Text>
           </Box>
            <Box width={"100%"} display={"flex"} alignSelf={"flex-end"} flexDirection={"column"} gap={"12px"}>
                <Button
                text={"Email US"}
                fontColor={"#CCCCCC"}
                fontSize={"13px"}
                fontWeight={500}
                bg={"#1F1F1F"}
                borderRadius={"10px"}
                width={"100%"}
                svg={"email"}
                TextMargin={"15px 10px"}
                />
                <Button
                text={"Schedule a Call"}
                fontColor={"#CCCCCC"}
                fontSize={"13px"}
                fontWeight={500}
                bg={"#1F1F1F"}
                borderRadius={"10px"}
                width={"100%"}
                svg={"calendar"}
                TextMargin={"15px 10px"}/>
            </Box>
        </Box>
    )
}

export default MeetingCard;