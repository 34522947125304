// import { useState } from "react";
// import { Box } from "@chakra-ui/react"; // Adjust according to your UI library
// import "./NavBar.css";
// import AppColors from "../../Utils/Color";

// const NavBar = ({ tabs }) => {
//   const [selectedTab, setSelectedTab] = useState(tabs[0]);

//   // Get the index of the selected tab for slider positioning
//   const selectedIndex = tabs.indexOf(selectedTab);

//   return (
//     <div style={{ position: "relative", display: "inline-block" }}>
//       <div
//         className="tab-slider"
//         style={{
//           width: `calc(100% / ${tabs.length} - 10px)`, // Adjust width of the slider
//           left: `calc(${(100 / tabs.length) * selectedIndex}% + 5px)`, // Adjust slider position
//         }}
//       />
//       {tabs.map((tab, index) => (
//         <Box
//           key={tab}
//           width={100}
//           className={`tab ${selectedTab === tab ? "selected" : ""}`}
//           // className="tab"
//           onClick={() => setSelectedTab(tab)}
//         >
//           {tab}
//         </Box>
//       ))}
//     </div>
//   );
// };

// export default NavBar;

/* -------------------------------------------------  New Nav Design ---------------------------------------------- */

import React from "react";
import { Box } from "@chakra-ui/react";
import "./NavBar.css";
import AppColors from "../../Utils/Color";

const NavBar = ({tabs}) => {
  return(
      <Box className="navContainer">
      {tabs.map((tab, index) => (
        <Box
          key={tab}
          width={100}
          className="tab"
        >
          {tab}
        </Box>
      ))}
    </Box>
  )
}

export default NavBar;