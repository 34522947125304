import { Box, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Avatar } from "@chakra-ui/react";
import { ReactComponent as RightArrow } from "../../Utils/SVGs/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../Utils/SVGs/LeftArrow.svg";
import "./Comment.css";
import AppColors from "../../Utils/Color";

const Comment = () => {
  const testimonials = [
    {
      userImage: "https://randomuser.me/api/portraits/women/44.jpg",
      companyLogo: "",
      comment:
        "Softations delivered outstanding web solutions that elevated our digital presence significantly. Their attention to detail and understanding of our business needs were unmatched.",
      personName: "Anna Green",
      designation: "CEO, GreenTech Solutions",
    },
    {
      userImage: "https://randomuser.me/api/portraits/men/32.jpg",
      companyLogo: "",
      comment:
        "The collaboration with Softations was seamless from start to finish. Their team is responsive, creative, and consistently delivers top-notch results.",
      personName: "David Parker",
      designation: "Marketing Director, Parker & Co.",
    },
    {
      userImage: "https://randomuser.me/api/portraits/women/68.jpg",
      companyLogo: "",
      comment:
        "Haroon Riaz and his leadership were instrumental in driving our project forward. Softations exceeded our expectations in terms of quality and efficiency.",
      personName: "Sophia Miller",
      designation: "Founder, Miller Retail Group",
    },
    {
      userImage: "https://randomuser.me/api/portraits/men/54.jpg",
      companyLogo: "",
      comment:
        "We partnered with Softations for a complex development project. Their ability to manage the project with both Raza Khan and Adeel Saleem’s expertise made it a success. They truly understand how to deliver on time and beyond expectations.",
      personName: "James Carter",
      designation: "COO, Carter Logistics",
    },
    {
      userImage: "https://randomuser.me/api/portraits/women/81.jpg",
      companyLogo: "",
      comment:
        "Softations transformed our brand’s digital presence, delivering a modern, sleek website that improved our engagement and user experience.",
      personName: "Olivia Taylor",
      designation: "CMO, Taylor Enterprises",
    },
    {
      userImage: "https://randomuser.me/api/portraits/men/72.jpg",
      companyLogo: "",
      comment:
        "The professionalism, creativity, and technical expertise of Softations are what truly set them apart. We look forward to continuing our partnership with them.",
      personName: "Liam Brown",
      designation: "Tech Lead, Brown Solutions",
    },
  ];

  const [currentTestimonials, setCurrentTestimonials] = useState(testimonials);
  const [animating, setAnimating] = useState(false);
  const [direction, setDirection] = useState("");
  const [moveBackward, setMoveBackward] = useState(false);
  const [moveForward, setMoveForward] = useState(false);

  const rearrangeTestimonials = (index) => {
    const rearranged = [
      ...currentTestimonials.slice(index),
      ...currentTestimonials.slice(0, index),
    ];
    setCurrentTestimonials(rearranged);
  };

  const handleNextClick = () => {
    if (animating) return;
    setAnimating(true);
    setDirection("right");

    setTimeout(() => {
      setMoveBackward(true);
    }, 500);

    setTimeout(() => {
      setAnimating(false);
      rearrangeTestimonials(1);
      setMoveBackward(false);
    }, 800);
  };

  const handlePreviousClick = () => {
    if (animating) return;
    setAnimating(true);
    setMoveForward(true);

    setTimeout(() => {
      rearrangeTestimonials(testimonials.length - 1);
      setDirection("left");
      setMoveForward(false);
      setAnimating(false);
    }, 300);
  };

  return (
    <Box className="commentContainer">
      <Box
        fontSize={"30px"}
        fontWeight={700}
        color={"#27262B"}
        flexBasis={"40%"}
      >
        <Text className="commentSideText">
          What our happy clients say about Softations!
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"60%"}
        overflowX={"hidden"}
      >
        <Box display={"flex"} overflow={"hidden"}>
          {currentTestimonials.map((item, index) => {
            const moveIndexesBack = index >= 1;
            const moveIndexesForward = index >= 0;
            return (
              <Box
                key={index}
                className={`commentCard  ${
                  direction === "right"
                    ? `${
                        index == 0 && animating ? "testimonial-shrink " : ""
                      } ${moveBackward && moveIndexesBack ? "Slide-Back " : ""}`
                    : index == 0
                    ? " testimonial-expand "
                    : ""
                }  ${moveForward && moveIndexesForward ? " Slide-forward " : ""}
      `}
                style={{ transition: "transform 0.5s ease, opacity 0.5s ease" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"flex-start"}
                  gap={"20px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Avatar
                      ml={"-7px"}
                      name={item.personName}
                      borderRadius={"50%"}
                      backgroundColor={AppColors.Green}
                      objectFit={"contain"}
                      height={"53px"}
                      width={"53px"}
                      src={item.userImage}
                    />
                    {item.companyLogo && (
                      <Box
                        height={"53px"}
                        maxWidth={"100px"}
                        objectFit={"contain"}
                      >
                        <Image
                          height={"100%"}
                          width={"auto"}
                          src={item.companyLogo}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    overflowY={"auto"}
                    maxHeight={"275px"}
                    fontSize={"17px"}
                    color={"#27262B"}
                    __css={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {item.comment}
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  flexDirection={"column"}
                  gap={"10px"}
                >
                  <Text
                    margin={"0px"}
                    fontSize={"18px"}
                    fontWeight={600}
                    color={"#27262B"}
                  >
                    {item.personName}
                  </Text>
                  <Text
                    margin={"0px"}
                    fontSize={"15px"}
                    fontWeight={400}
                    color={"#27262B"}
                    opacity={"0.6"}
                  >
                    {item.designation}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box className="arrowContainer">
          <LeftArrow onClick={handlePreviousClick} cursor={"pointer"} />
          <RightArrow onClick={handleNextClick} cursor={"pointer"} />
        </Box>
      </Box>
    </Box>
  );
};

export default Comment;
