
import { Box, Text } from "@chakra-ui/react";
import React from "react";
import AppColors from "../../Utils/Color";
import {ReactComponent as Calendar} from "../../Utils/SVGs/Calendar.svg";
import {ReactComponent as Email} from "../../Utils/SVGs/Email.svg";

const Button = ({
  text,
  width,
  height,
  fontSize,
  fontWeight,
  bg,
  fontColor,
  borderRadius,
  padding,
  TextMargin,
  className,
  svg
}) => {
  return (
    <Box
      width={width}
      height={height}
      padding={padding}
      borderRadius={borderRadius ? borderRadius : 50}
      backgroundColor={bg ? bg : AppColors.Green}
      fontSize={fontSize}
      fontWeight={fontWeight}
      className={className ? className : ""}
      style={{
        display:"flex",
        alignItems:"center",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        cursor:"pointer"
      }}
    >
      {
        svg == "calendar" ? <Calendar/> : svg == "email" ? <Email/> : ""
      }
      <Text color={fontColor ? fontColor : "black"} margin={TextMargin ? TextMargin : "16px 0px"}>{text}</Text>
    </Box>
  );
};

export default Button;




// import { Box, Text } from "@chakra-ui/react";
// import React from "react";
// import AppColors from "../../Utils/Color";

// const Button = ({
//   text,
//   fontSize,
//   fontWeight,
//   bg,
//   fontColor,
// }) => {
//   return (
//     <Box
//       cursor={"pointer"}
//       backgroundColor={bg ? bg : AppColors.Black}
//       fontSize={fontSize}
//       fontWeight={fontWeight}
//       style={{
//         borderRadius: 16,
//         textAlign: "center",
//         justifyContent: "center",
//         alignContent: "center",
//         padding:"15px 20px",
//       }}
//     >
//       <Text color={fontColor} margin={"0px"}>{text}</Text>
//     </Box>
//   );
// };

// export default Button;