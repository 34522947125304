import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import AppColors from "../../Utils/Color";
import NavBar from "../Navbar/NavBar";
import Button from "../Button/Button";
import "./Header.css";
import MainBackground from "../Images/75.png";
import { ReactComponent as Softations } from "../../Utils/SVGs/Softations.svg";
import { ReactComponent as BackgroundLogo } from "../../Utils/SVGs/BackgroundSoftationsLogo.svg";
import { ReactComponent as Threedot } from "../../Utils/SVGs/Threedot.svg";
import ServiceCard from "../ServicesCard/ServiceCard";

const tabs = ["Services", "Case Studies", "About", "Contact Us"];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const panelRef = useRef(null);

  const handleTogglePanel = () => {
    console.log("triggered");
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (panelRef.current && !panelRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Box
      style={{
        backgroundImage: `url(${MainBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
      }}
      className="header_container"
    >
      <Box position={"absolute"} zIndex={0} right={0} top={0}>
        <BackgroundLogo className="header_background_logo" />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
          marginBottom: window.innerWidth < 850 ? "20px" : "0px",
        }}
      >
        <Box>
          <Softations className="softation_header_logo" />
        </Box>
        {window.innerWidth < 850 ? (
          <>
            <Box display={"flex"} zIndex={1} alignItems={"center"} gap={"20px"}>
              {window.innerWidth > 400 && (
                <Button
                  className={"header_top_button"}
                  text={"Get A Free Quote"}
                  fontSize={16}
                  TextMargin={"0px"}
                  fontColor={AppColors.Green}
                  bg={AppColors.Black}
                  padding={"12px 20px"}
                  borderRadius={"16px"}
                  fontWeight={700}
                />
              )}
              <div onClick={handleTogglePanel}>
                <Threedot style={{ cursor: "pointer" }} />
              </div>
              <div
                ref={panelRef}
                className={`panel-container ${isOpen ? "open" : "closed"}`}
              >
                <div className="panel-content">
                  <p className="NavOption">Services</p>
                  <p className="NavOption">Case Studies</p>
                  <p className="NavOption">About</p>
                  <p className="NavOption">Contact Us</p>
                  <Button
                    width={"100%"}
                    text={"Get A Free Quote"}
                    fontSize={16}
                    TextMargin={"0px"}
                    fontColor={AppColors.Green}
                    bg={AppColors.Black}
                    padding={"15px 20px"}
                    borderRadius={"8px"}
                    fontWeight={700}
                  />
                </div>
              </div>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <NavBar tabs={tabs} />
            </Box>
            <Box>
              <Button
                className={"header_top_button"}
                text={"Get A Free Quote"}
                fontSize={16}
                TextMargin={"0px"}
                fontColor={AppColors.Green}
                bg={AppColors.Black}
                padding={"12px 20px"}
                borderRadius={"16px"}
                fontWeight={700}
              />
            </Box>
          </>
        )}
      </Box>
      <Box className="text_serviceCard_contianer">
        <Box className="welcome">Welcome to Softations</Box>
        <Box className="bigText">
          A software house that makes Startups stand out!
        </Box>
        <Box className="smallText">
          Partner With Software Developers Who Know The Startup World –
          Affordable, Agile, And Always On Your Side
        </Box>
        <Box className="service_card">
          <ServiceCard />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
