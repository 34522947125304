import React from "react";
import { Box } from "@chakra-ui/react";
import { ReactComponent as SoftwareDevelopment } from "../../Utils/SVGs/SoftwareDevelopment.svg";
import { ReactComponent as MobileDevelopment } from "../../Utils/SVGs/MobileDevelpoment.svg";
import { ReactComponent as SoftwareConsulting } from "../../Utils/SVGs/SoftwareConsulting.svg";
import { ReactComponent as WebDevelopment } from "../../Utils/SVGs/WebDevelopment.svg";
import { ReactComponent as UIUX } from "../../Utils/SVGs/UI-UX.svg";
import "./ServiceCard.css";

const ServiceCard = ({ title }) => {
  return (
    <>
      <Box className="cardContainer">
        <Box>
          <SoftwareDevelopment />
        </Box>
        <Box className="title">Custom Software Development</Box>
      </Box>

      <Box className="cardContainer">
        <Box>
          <MobileDevelopment />
        </Box>
        <Box className="title">Mobile App Development</Box>
      </Box>

      <Box className="cardContainer">
        <Box>
          <SoftwareConsulting />
        </Box>
        <Box className="title">Software Development Consulting</Box>
      </Box>

      <Box className="cardContainer">
        <Box>
          <WebDevelopment />
        </Box>
        <Box className="title">Web Application Development</Box>
      </Box>

      <Box className="cardContainer">
        <Box>
          <UIUX />
        </Box>
        <Box className="title">UI/UX Design</Box>
      </Box>
    </>
  );
};

export default ServiceCard;
