// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Remove blue background on active state in mobile browsers */
*{
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none; 
  user-select: none;  
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,8DAA8D;AAC9D;EACE,wCAAwC;EACxC,yBAAyB;EAGzB,iBAAiB;AACnB","sourcesContent":["/* Remove blue background on active state in mobile browsers */\n*{\n  -webkit-tap-highlight-color: transparent;\n  -webkit-user-select: none;\n  -moz-user-select: none;   \n  -ms-user-select: none; \n  user-select: none;  \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
