import React from "react";
import { ReactComponent as HappyFace } from "../../Utils/SVGs/HappyFace.svg";
import { ReactComponent as Award } from "../../Utils/SVGs/Award.svg";
import { ReactComponent as Team } from "../../Utils/SVGs/Team.svg";
import { Box, Divider, Text } from "@chakra-ui/react";

const QualitiesCard = ({ text, description, svg }) => {
  return (
    <Box gap={"18px"} display={"flex"} flexDirection={"column"} padding={window.innerWidth > 500 ? "47px":"30px"} border={"1px solid rgba(255, 255, 255, 0.1)"} backgroundColor={"rgba(255, 255, 255, 0.02)"} borderRadius={1}>
      { svg === "Customer" ? (
        <HappyFace />
      ) : svg === "Award" ? (
        <Award />
      ) : (
        <Team />
      )}

      <Divider h={"1px"} w={"100%"} bg={"rgba(239, 241, 244, 0.1)"} />

      <Text fontSize={30} fontWeight={400} color={"#EFF1F4"}>
        {text}
      </Text>
      <Text opacity={"0.7"} fontSize={16} fontWeight={400} color={"#EFF1F4"}>
        {description}
      </Text>
    </Box>
  );
};

export default QualitiesCard;
