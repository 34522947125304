// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardContainer{
    box-sizing: border-box;
    height: 170px;
    background-color: #151413;
    border-radius: 17px;
    padding: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 500px) {
    .cardContainer{
        height: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        gap: 10px;
        justify-content: start;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ServicesCard/ServiceCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;QACb,aAAa;QACb,mBAAmB;QACnB,mBAAmB;QACnB,aAAa;QACb,SAAS;QACT,sBAAsB;IAC1B;AACJ","sourcesContent":[".cardContainer{\n    box-sizing: border-box;\n    height: 170px;\n    background-color: #151413;\n    border-radius: 17px;\n    padding: 17px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.title{\n    color: #FFFFFF;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n@media screen and (max-width: 500px) {\n    .cardContainer{\n        height: 120px;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        padding: 20px;\n        gap: 10px;\n        justify-content: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
