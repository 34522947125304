// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the container of tabs has a relative position for the slider positioning */
/* .tab-container {
    position: relative;
    display: inline-block;
} */

/* Style the individual tabs */
/* .tab {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-block;
    border-radius: 50px;
    color: white;
    padding: 5px 10px;
    transition: color 0.3s ease, transform 0.3s ease;
    position: relative;
    z-index: 2;
    cursor: pointer;
} */

/* Highlight the selected tab */
/* .tab.selected {
    color: #0A0B0A;
} */

/* Style for the slider */
/* .tab-slider {
    position: absolute;
    border-radius: 50px;
    height: 100%;
    transition: left 0.3s ease;
    background-color: #ADFDA3;
    z-index: 1;
} */

/* -------------------------------------------------  New Design ---------------------------------------------- */

.navContainer{
display: flex;
align-items: center;
padding: 8px 16px;
gap: 19px;
}

.tab{
    padding: 8px 4px;
    color: #0A0B0A;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/NavBar.css"],"names":[],"mappings":"AAAA,oFAAoF;AACpF;;;GAGG;;AAEH,8BAA8B;AAC9B;;;;;;;;;;;;GAYG;;AAEH,+BAA+B;AAC/B;;GAEG;;AAEH,yBAAyB;AACzB;;;;;;;GAOG;;AAEH,iHAAiH;;AAEjH;AACA,aAAa;AACb,mBAAmB;AACnB,iBAAiB;AACjB,SAAS;AACT;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["/* Ensure the container of tabs has a relative position for the slider positioning */\n/* .tab-container {\n    position: relative;\n    display: inline-block;\n} */\n\n/* Style the individual tabs */\n/* .tab {\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    display: inline-block;\n    border-radius: 50px;\n    color: white;\n    padding: 5px 10px;\n    transition: color 0.3s ease, transform 0.3s ease;\n    position: relative;\n    z-index: 2;\n    cursor: pointer;\n} */\n\n/* Highlight the selected tab */\n/* .tab.selected {\n    color: #0A0B0A;\n} */\n\n/* Style for the slider */\n/* .tab-slider {\n    position: absolute;\n    border-radius: 50px;\n    height: 100%;\n    transition: left 0.3s ease;\n    background-color: #ADFDA3;\n    z-index: 1;\n} */\n\n/* -------------------------------------------------  New Design ---------------------------------------------- */\n\n.navContainer{\ndisplay: flex;\nalign-items: center;\npadding: 8px 16px;\ngap: 19px;\n}\n\n.tab{\n    padding: 8px 4px;\n    color: #0A0B0A;\n    font-size: 13px;\n    text-align: center;\n    font-weight: 600;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
