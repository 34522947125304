import React, { useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
  } from '@chakra-ui/react'
import {ReactComponent as AddIcon}  from "../../Utils/SVGs/AddIcon.svg";
import {ReactComponent as MinusIcon}  from "../../Utils/SVGs/MinusIcon.svg";
import "./AccordionMap.css";

const AccordionMap = () => {

  const [isOpen , setIsOpen] = useState(null);

  const handleAccordion = (index) => {
    if(index != isOpen){
      setIsOpen(index);
    }
    else{
      if(isOpen == index){
        setIsOpen(null);
      }
      else{
        setIsOpen(index);
      }
    }
  }

  const FAQ = [
    {
      question: "What makes Softations the ideal software development partner for startups?",
      description: "Softations is a platform that is committed to assisting startups to work through the problems that they encounter in software development, especially as they set up and build their businesses. As such, we aim to give our clients the quintessential and highly affordable products that communicate their needs throughout the development process."
    },
    {
      question: "How does Softations ensure affordability without compromising quality?",
      description: "It is very important that in our case we understand that startup businesses work under very limited budgets. Softations utilizes an agile approach to system development and effective and efficient project management that will provide valuable returns on investment for quality software."
    },
    {
      question: "What services does Softations offer to support startup growth?",
      description: "Some of the services that Softations provides involve custom software development, full-stack web development, mobile application development, UI/UX design, and SDC. To help startups achieve the best results with limited resources we offer cutting-edge solutions that can be implemented in today’s economy."
    },
    {
      question: "How does Softations handle project management to ensure timely delivery?",
      description: "At Softations, we make use of best practices for managing projects such as using Scrum development to manage projects. This approach enables us to deliver our services within the stipulated time and at the same time offer the quality and precision that most of the clients value."
    },
    {
      question: "What is Softation's approach to communication during the development process?",
      description: "Business witnessing has proved that effective communication is central to software development. This full communication is from the initiation of the project to the completion of the project so that we meet the visions and needs of the client."
    }
  ];
  

  return (
    <Accordion margin={"20px 0px 40px 0px"} allowToggle>
      {
        FAQ.map((item , index , arr)=>(
          <AccordionItem  marginBottom={index == arr.length ? "0px":"16px"} border={"none"} key={index}>
          <AccordionButton onClick={()=> handleAccordion(index)} className="accordionBtn">
            <Box className="FAQHeadingBox">
            {isOpen == index ? (<MinusIcon style={{flexShrink:0.5}}/>):(<AddIcon style={{flexShrink:0.5}}/>)}
            <Box className="FAQText">
              {item.question}
            </Box>
            </Box>
          </AccordionButton>
        <AccordionPanel className="FAQText" color={"#191921"} pb={4}>
          {item.description}
        </AccordionPanel>
      </AccordionItem>
        ))
      }
    </Accordion>
  );
};

export default AccordionMap;
