// import { Box, Text } from "@chakra-ui/react";
// import React from "react";
// import { ReactComponent as Startup } from "../../Utils/SVGs/startup.svg";
// import { ReactComponent as Enterprise } from "../../Utils/SVGs/Enterprise.svg";
// import { ReactComponent as SME } from "../../Utils/SVGs/SME.svg";
// import AppColors from "../../Utils/Color";

// const EnterpriseCard = ({ title, description, type }) => {
//   return (
//     <Box
//       width={"33%"}
//       backgroundColor={"rgba(246, 247, 249, 0.6)"}
//       paddingTop={"20px"}
//       borderTopLeftRadius={30}
//       borderTopRightRadius={30}
//       borderBottomRightRadius={type === "Enterprise" ? 30 : 0}
//       borderBottomLeftRadius={type === "Startup" ? 30 : 0}
//       display={"flex"}
//       flexDirection={"column"}
//       justifyContent={"space-between"}
//     >
//       <Box paddingLeft={20} pr={20}>
//         <Text fontSize={24} fontWeight={700} m={0}>
//           {title}
//         </Text>
//         <Text
//           fontWeight={300}
//           fontSize={16}
//           m={0}
//           color={"rgba(71, 84, 103, 1)"}
//         >
//           {description}
//         </Text>
//       </Box>
//       <Box
//         display={"flex"}
//         justifyContent={
//           type === "Enterprise"
//             ? "flex-start"
//             : type === "SME"
//             ? "center"
//             : "flex-end"
//         }
//         marginRight={type === "Enterprise" ? 40 : 0}
//       >
//         {type === "Enterprise" ? (
//           <Enterprise />
//         ) : type === "SME" ? (
//           <SME />
//         ) : (
//           <Startup />
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default EnterpriseCard;

/*  --------------------------------------------  New Design  --------------------------------------------- */

import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as PlayAble } from "../../Utils/SVGs/PlayAble.svg";
import { ReactComponent as Flow } from "../../Utils/SVGs/Flow.svg";
import { ReactComponent as Collaboration } from "../../Utils/SVGs/Collaboration.svg";
import { ReactComponent as Adeel } from "../../Utils/SVGs/Adeel.svg";
import { ReactComponent as Haroon } from "../../Utils/SVGs/Haroon.svg";
import { ReactComponent as StarLogo } from "../../Utils/SVGs/Star.svg";
import { ReactComponent as IPhone } from "../../Utils/SVGs/iPhone.svg";
import { ReactComponent as FlowPreview } from "../../Utils/SVGs/FlowPreview.svg";
import "./EnterpriseCard.css";

import AppColors from "../../Utils/Color";
import Button from "../Button/Button";

const Star = ({ style }) => {
  return <StarLogo className="star" style={style} />;
};

const EnterpriseCard = ({ title, description, type }) => {
  
  const generateRandomStars = (numStars) => {
    const stars = [];
    const minDistance = 10; // Minimum distance between stars (in percentage)
  
    const isTooClose = (newStar, existingStars) => {
      return existingStars.some((star) => {
        const dx = parseFloat(star.left) - parseFloat(newStar.left);
        const dy = parseFloat(star.top) - parseFloat(newStar.top);
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < minDistance;
      });
    };
  
    for (let i = 0; i < numStars; i++) {
      let newStar;
      do {
        newStar = {
          id: i,
          left: `${Math.random() * 80}%`,
          top: `${Math.random() * 80}%`,
          animationDelay: `${Math.random() * 2}s`,
        };
      } while (isTooClose(newStar, stars));
  
      stars.push(newStar);
    }
  
    return stars;
  };
  
  const [stars, setStars] = useState(generateRandomStars(6));
  
  useEffect(() => {
    const interval = setInterval(() => {
      setStars(generateRandomStars(6));
    }, 10000);
  
    return () => clearInterval(interval);
  }, []);
  

  return (
    <Box
      className="enterpriseCard"
      backgroundColor={type == "BelowFAQ" ?AppColors.Black:"white"}
      paddingBottom={type == "UI/UX Design" || type == "Custom Software Development" ? "20px":"0px"}
      display={"flex"}
    >
      <Box padding={"20px"} display={(type === "Last Row" || type === "Special" || type === "BelowFAQ" ) ? "none" : ""}>
        <Text fontSize={19} color={AppColors.Black} fontWeight={700} m={"0 0 8px 0"}>
          {title}
        </Text>
        <Text
          fontWeight={300}
          fontSize={16}
          m={0}
          color={"rgba(11, 10, 10, 0.6)"}
        >
          {description}
        </Text>
      </Box>
      <Box
        className="svgContainer"
        justifyContent={(window.innerWidth <= 600 && type == "Web Application Development") ? "left":"center"}
      >
        {type === "UI/UX Design" ? (
          <PlayAble />
        ) : type === "Custom Software Development" ? (
          <Box
            padding={"25px"}
            border={"1px solid rgba(173, 253, 163, 0.1)"}
            borderRadius={"105px"}
            position="relative"
          >
            {stars.map((star) => (
              <Star
                key={star.id}
                style={{
                  position: "absolute",
                  zIndex: -1,
                  left: star.left,
                  top: star.top,
                  animationDelay: star.animationDelay,
                }}
              />
            ))}
            <Box
              padding={"25px"}
              border={"1px solid rgba(173, 253, 163, 0.3)"}
              borderRadius={"90px"}
            >
              <Box
                padding={"25px"}
                border={"1px solid rgba(173, 253, 163, 0.6)"}
                borderRadius={"68px"}
              >
                <Box
                  padding={"25px"}
                  border={"1px solid #ADFDA3"}
                  borderRadius={"50px"}
                >
                  <Box
                    backgroundColor={"#ADFDA3"}
                    padding={"25px"}
                    zIndex={1}
                    border={"1px solid #0D6502"}
                    borderRadius={"30px"}
                  >
                    <Flow />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : type === "Mobile App Development" ? (
        <IPhone style={{height:"100%"}}/>
        ) : type === "Web Application Development" ? (
          <FlowPreview className="flowPreview"/>
        ) 
        : type === "Software Development Consulting" ?
         (
          <div style={{position:"relative"}}>
            <Adeel style={{position:"absolute",top:"80%",right:"90%"}}/>
            <Collaboration/>
            <Haroon style={{position:"absolute",top:"22%",left:"80%"}}/>
          </div>
        ):type === "Last Row" ?(
         <Box className="commonStyle">
         <Text textAlign={"center"}>Our group of expert developers guarantees that all solutions are
          created to boost the effectiveness of your operations and give you the
           competitive advantage you need in the market.
           </Text>
           <Button className={"quoteBtn"} borderRadius={"16px"} bg={AppColors.Black} fontColor={AppColors.Green} 
           text={"Get a quote"} TextMargin={"0px"} fontSize={"16px"} fontWeight={"600"}/>
         </Box>
        ): type === "Special" ?(
          <Box className="commonStyle">
         <Text textAlign={"center"}>You just found the software partner who gets Startups!
          <br/><Text margin={"16px 0px 0px 0px"} color={"black"} fontSize={"16px"} fontWeight={300}>We empower Startups by delivering top-quality, secure, and adaptable digital solutions. Millions of <br/>people rely on our services, whether to meet today’s demands or to open up possibilities for <br/>tomorrow, let Softations help your Startup succeed. </Text>
         </Text>
           <Button borderRadius={"16px"} bg={AppColors.Black} fontColor={AppColors.Green} 
           text={"LET DRIVE YOUR SUCCESS WITH US :)"} TextMargin={"0px"} fontSize={"16px"} fontWeight={"600"} className={"successBtn"}/>
         </Box>
        ): type === "BelowFAQ" ?(
          <Box className="commonStyle" color={"white"}>
         <Text className="contactText">Let’s shape the future of software development together!</Text>
           <Button className={"contactBtn"} borderRadius={"16px"} bg={AppColors.Green} fontColor={AppColors.Black} 
           text={"Get in touch with us!"} TextMargin={"0px"} fontSize={"16px"} fontWeight={"600"} />
         </Box>
        ) : (<></>)}
      </Box>
    </Box>
  );
};

export default EnterpriseCard;
